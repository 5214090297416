import React, { useState, useEffect } from "react";
import styled from "styled-components";

const CHARACTER_STATS_KEY = "characterStats";

const Container = styled.main`
  display: flex;
  width: 100vw;
  flex-direction: column;
`;

const LongRestButton = styled.button`
  border-radius: 8px;
  border: 2px solid black;
  background-color: white;
  margin: 5px;
  min-height: 50px;
  cursor: pointer;
  flex: 1;
  &:hover {
    background-color: lightgray;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const HpStatsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: black;
  border-radius: 8px;
  margin: 5px;
  padding: 1px;
  justify-content: flex-end;
`;

const HpStatsContainerText = styled.div`
  text-align: center;
  color: white;
  font-weight: 800;
  padding: 0px 2px;
`;

const HpStats = styled.div`
  text-align: center;
  background-color: white;
  border-radius: 8px;
  flex: 1;
  font-size: 30px;
  padding: 10px 0px;
`;

const AbilitiesTitle = styled.div`
  text-align: left;
  background-color: white;
  flex: 1;
  font-size: 35px;
  padding: 10px 10px;
  text-transform: uppercase;
`;

const DamageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const DamageInput = styled.input`
  border: 1px solid black;
  border-radius: 8px;
  margin: 5px;
  font-size: 20px;
  padding: 4px 8px;
  min-height: 0px;
`;

const DamageButton = styled.button`
  border-radius: 8px;
  border: 2px solid black;
  background-color: white;
  margin: 5px;
  min-height: 30px;
  cursor: pointer;
  color: red;
  flex: 1;
  &:active {
    background-color: red;
  }
`;

const HealButton = styled.button`
  border-radius: 8px;
  border: 2px solid black;
  background-color: white;
  margin: 5px;
  min-height: 30px;
  cursor: pointer;
  color: greenyellow;
  flex: 1;
  &:active {
    background-color: greenyellow;
  }
`;

const RegainButton = styled.button`
  border-radius: 8px;
  border: 2px solid black;
  background-color: white;
  margin: 5px;
  min-height: 30px;
  cursor: pointer;
  color: blueviolet;
  &:active {
    background-color: blueviolet;
  }
`;

const AbilityCard = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const AbilityTitle = styled.div`
  text-align: left;
  background-color: white;
  flex: 1;
  font-size: 25px;
  padding: 10px 10px;
  text-transform: uppercase;
`;

const UseButton = styled.button`
  text-align: center;
  border-radius: 20px;
  border: 0;
  background-color: white;
  margin: 5px;
  cursor: pointer;
  font-size: 40px;
`;

function App() {
  const renewableType = {
    long: "LongRest",
    short: "ShortRest",
    down: "Down",
    never: "Never",
    custom: "Custom",
  };

  const statType = {
    ability: "Ability",
    hp: "HP",
  };

  const initialState = {
    hp: {
      max: 69,
      current: 69,
      renewableType: renewableType.long,
      statType: statType.hp,
    },
    temporaryHp: {
      current: 0,
      renewableType: renewableType.never,
      statType: statType.hp,
    },
    DisguiseSelf: {
      max: 1,
      current: 1,
      renewableType: renewableType.long,
      statType: statType.ability,
      title: "[Tiefling][L] Disguise Self",
    },
    Invisibility: {
      max: 1,
      current: 1,
      renewableType: renewableType.long,
      statType: statType.ability,
      title: "[Tiefling][L] Invisibility",
    },
    Hexblade: {
      max: 1,
      current: 1,
      renewableType: renewableType.short,
      statType: statType.ability,
      title: "[S] Hexblade Curse",
    },
    FireShoes: {
      max: 1,
      current: 1,
      renewableType: renewableType.short,
      statType: statType.ability,
      title: "[S] Fire shoes",
    },
    bardicInspiration: {
      max: 5,
      current: 5,
      renewableType: renewableType.short,
      statType: statType.ability,
      title: "[S] Bardic inspiration",
    },
    bardFirstLevelSpellSlots: {
      max: 4,
      current: 4,
      renewableType: renewableType.long,
      statType: statType.ability,
      title: "[Bard] 1-lvl spell slots",
    },
    warlocFirstLevelSpellSlots: {
      max: 1,
      current: 1,
      renewableType: renewableType.short,
      statType: statType.ability,
      title: "[Warlock] 1-lvl spell slots",
    },
    bardScondLevelSpellSlots: {
      max: 3,
      current: 3,
      renewableType: renewableType.long,
      statType: statType.ability,
      title: "[Bard] 2-lvl spell slots",
    },
    bardThirdLevelSpellSlots: {
      max: 3,
      current: 3,
      renewableType: renewableType.long,
      statType: statType.ability,
      title: "[Bard] 3-lvl spell slots",
    },
    bardFourthLevelSpellSlots: {
      max: 3,
      current: 3,
      renewableType: renewableType.long,
      statType: statType.ability,
      title: "[Bard] 4-lvl spell slots",
    },
    bardFifthLevelSpellSlots: {
      max: 2,
      current: 2,
      renewableType: renewableType.long,
      statType: statType.ability,
      title: "[Bard] 5-lvl spell slots",
    },
    staffOfFrost: {
      max: 10,
      current: 10,
      renewableType: renewableType.down,
      statType: statType.ability,
      title: "[Item][Recharge 1d6 + 4 daily at down] Staff of frost",
    },
    wingsOfFlying: {
      max: 1,
      current: 1,
      renewableType: renewableType.custom,
      statType: statType.ability,
      title: "[Item][Renew 1d12 hours] Wings of flying",
    },
  };

  const savedItem = localStorage.getItem(CHARACTER_STATS_KEY);

  const [characterStats, setCharacterStats] = useState(
    savedItem ? JSON.parse(savedItem) : initialState
  );

  useEffect(() => {
    localStorage.setItem(CHARACTER_STATS_KEY, JSON.stringify(characterStats));
  }, [characterStats]);

  const [currentHpInput, setcurrentHpInput] = useState(0);

  function handleRenewOn(types) {
    const newState = { ...characterStats };

    Object.keys(newState).forEach((key) => {
      const element = newState[key];

      if (types.includes(element.renewableType)) {
        element.current = element.max;
      }
    });

    setCharacterStats(newState);
  }

  function handleAbilityExpend(key) {
    const newState = { ...characterStats };
    const element = newState[key];

    if (element.current > 0) {
      element.current -= 1;
    }

    setCharacterStats(newState);
  }

  function handleAbilityRegain(key) {
    const newState = { ...characterStats };
    const element = newState[key];

    element.current = element.max;

    setCharacterStats(newState);
  }

  function handleHpInputChange(value) {
    isNaN(value) ? setcurrentHpInput(0) : setcurrentHpInput(Math.abs(value));
  }

  function handleDamageChange() {
    setCharacterStats((prevState) => ({
      ...prevState,
      hp: {
        ...prevState.hp,
        current: Math.max(prevState.hp.current - currentHpInput, 0),
      },
    }));
  }

  return (
    <Container>
      <Row>
        <LongRestButton
          onClick={() =>
            handleRenewOn([renewableType.long, renewableType.short])
          }
        >
          Long Rest
        </LongRestButton>
        <LongRestButton onClick={() => handleRenewOn([renewableType.short])}>
          Short Rest
        </LongRestButton>
      </Row>
      <Row>
        <HpStatsContainer>
          <HpStatsContainerText>HP</HpStatsContainerText>
          <HpStats>
            {`${characterStats.hp.current} / ${initialState.hp.max}`}
          </HpStats>
        </HpStatsContainer>
        <DamageContainer>
          <DamageInput
            onChange={(e) => handleHpInputChange(e.target.value)}
            value={currentHpInput}
          />
          <Row>
            <DamageButton onClick={() => handleDamageChange()}>
              Damage!
            </DamageButton>
            <HealButton
              onClick={() =>
                setCharacterStats((prevState) => ({
                  ...prevState,
                  hp: {
                    ...prevState.hp,
                    current: Math.min(
                      prevState.hp.current + currentHpInput,
                      prevState.hp.max
                    ),
                  },
                }))
              }
            >
              Heal!
            </HealButton>
          </Row>
        </DamageContainer>
      </Row>
      <Row>
        <AbilitiesTitle>Abilities</AbilitiesTitle>
      </Row>
      {Object.keys(characterStats)
        .filter((statKey) => {
          let stat = characterStats[statKey];

          return stat.statType === statType.ability;
        })
        .map((statKey) => {
          let stat = characterStats[statKey];

          return (
            <AbilityCard key={statKey}>
              <Row>
                <AbilityTitle>{stat.title}</AbilityTitle>
                <RegainButton onClick={() => handleAbilityRegain(statKey)}>
                  Regain!
                </RegainButton>
              </Row>
              <Row>
                {[...Array(stat.max)].map((_, i) => {
                  const image = i + 1 <= stat.current ? "🌕" : "🌑";

                  return (
                    <UseButton
                      key={i}
                      onClick={() => handleAbilityExpend(statKey)}
                    >
                      {image}
                    </UseButton>
                  );
                })}
              </Row>
            </AbilityCard>
          );
        })}
    </Container>
  );
}

export default App;
